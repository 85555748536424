@import url("https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.2.2/css/bootstrap.min.css");

@import "mixins";

#app,
html,
body {
  height: 100vh;
}

.action-buttons {
  font-weight: bolder;
  padding: 10px;
  flex-grow: 1;
  display: flex;
  max-width: 600px;
  input {
    width: 100%;
  }
}
.action-btn {
  button {
    @include for-mobile {
      font-size: 12px;
    }
  }
}
.action-btn:nth-child(1) {
  margin-right: 10px;
}
.add-actions {
  float: right;
  margin-bottom: 10px;
}
#admin-create {
  flex-direction: column;
  .contain {
    max-width: 500px;
    border: 1px solid #000;
    padding: 25px 20px;
  }

  input,
  select {
    width: 100%;
    padding: 5px;
    border: 3px solid #000;
    border-radius: 5px;
  }

  @include for-mobile {
    select {
      font-size: 38px;
    }
  }

  hr {
    margin: 20px 0px;
  }
}
#admin-create {
  @include for-mobile {
    height: 100% !important;
    align-items: flex-start;
    justify-content: flex-start !important;
  }
}
#admin-container {
  @include for-mobile {
    height: 100% !important;
    align-items: flex-start;
  }
}
#user-table {
  thead {
    background-color: blue;
    color: white;
  }
  tr {
    border-bottom: 1px solid #ddd;
  }
  thead,
  tbody {
    th,
    td {
      padding: 5px;
    }
  }
}
.permalink {
  border: 1px solid #000;
  color: #000;
  padding: 5px;

  position: relative;

  .copy-btn {
    display: block;
    background-color: #ddd;
    border: 1px solid #000;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 5px;
    right: 5px;
    text-align: center;

    :hover {
      cursor: pointer;
    }
  }
}
.modal {
  &.show {
    background-color: rgba(0, 0, 0, 0.45);
    display: block !important;
  }
  &.hide {
    display: none !important;
  }
}
.uttility-buttons {
  font-weight: bolder;
  padding: 10px;
  padding-left: 0px;
  display: flex;
  max-width: 600px;
  button:nth-child(1) {
    margin-right: 10px;
  }
  input {
    width: 100%;
  }
}
.action-btn-desktop {
  @include for-desktop {
    display: flex;
  }
  @include for-mobile {
    display: none;
  }
}
.action-btn-mobile {
  margin: 10px;
  @include for-desktop {
    display: none;
  }
  @include for-mobile {
    display: flex;
  }
}
.layout-container {
  height: 100%;
  padding-top: 45px;
}
.content-container {
  width: 80%;
  height: 100%;
  padding-top: 45px;
  padding-bottom: 45px;
  margin: 0 auto;
  .content-inner {
    font-weight: bolder;
    .logo-center {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }
}
.home-container {
  height: 100%;

  .row {
    height: 100%;
  }
}

.header-container {
  padding: 0px;
  margin-top: -45px;
  width: 100%;
  z-index: 1000;
  display: flex;
  position: fixed;
  background-color: #ddd;
  justify-content: space-between;
  border-bottom: 5px solid #000;
  .brand {
    font-weight: bolder;
    padding: 10px;
    :nth-child(2) {
      margin: 0 20px;
    }

    a {
      color: #000;
      text-decoration: none;
    }
  }
}
.search-result {
  li {
    border: 1px solid #000;
    padding: 10px;

    display: flex;
    justify-content: space-between;

    &:hover {
      background-color: #efefef;
    }

    button {
      margin-left: 10px;
    }
  }
}
.search-result-container,
.login-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.search-result-container {
  table {
    max-width: 550px;

    td {
      padding: 5px;
    }
  }
  @include for-mobile {
    display: block;
    height: 100%;
    margin-top: 50px;
    padding: 5px;

    td {
      padding: 5px;
    }

    td:nth-child(2) {
      display: flex;
    }

    a {
      text-overflow: ellipsis;
      word-break: break-word;
    }
  }
}
.loading-container {
  display: flex;
  align-items: center;
  line-height: 45px;

  @include for-mobile {
    margin-top: 15px;
    line-height: 25px;
    svg {
      height: 25px;
    }
  }
  &.fullsize {
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 450px;
  }
  p {
    margin: 0;
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
}
.extend-container {
  margin-top: 10px;
}
.button-container {
  display: flex;
  @include for-mobile {
    margin-top: 15px;
    margin-bottom: 15px;
    height: 20px;

    button {
      padding: 0px 5px;
      font-size: 10px;
    }
  }
  .input-group {
    display: flex;
    justify-content: end;
    .extend-input {
      display: inline-block;
      width: auto !important;
    }
    label {
      display: inline-block;
      width: auto !important;
      line-height: 38px;
      margin-left: 5px !important;
      @include for-mobile {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
.dashboard-upper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 100%;
  height: 10%;
  margin: 10px;
  .unit-item {
    &.reset-btn {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.dashboard-container {
  width: 80%;
  height: 80vh;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;

  @include for-mobile {
    width: 100%;
    height: 60vh;
  }

  .react-calendar__navigation {
    margin-bottom: 20px;
    button {
      padding: 10px 20px;

      @include for-mobile {
        padding: 10px 15px;
      }
    }
  }
  .react-calendar__navigation__arrow {
    margin-right: 1%;
    margin-left: 1%;
  }
  .react-calendar__navigation,
  .react-calendar__month-view__weekdays__weekday {
    text-align: center;
  }

  .react-calendar__tile--active {
    background: #ffff76;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }

  .react-calendar__month-view__days {
    button {
      border: 1px solid #ddd;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    background-color: rgb(6, 38, 245);
    border: 1px solid #ddd;
    color: #fff;
    padding: 10px;
  }

  .react-calendar__tile {
    padding: 10px;
    &:hover {
      background: #ffff76;
    }
  }
}
.upload-upper {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 100%;
  height: 10%;
  margin: 0 auto;
  min-height: 100px;

  @include for-mobile {
    height: 5%;
    padding: 0px 5px;
  }

  .upload-navigation {
    display: block;
    &:hover {
      cursor: pointer;
    }
  }

  @include for-mobile {
    width: 100%;
    min-height: 20px;

    h2 {
      font-size: 18px;
      margin-bottom: 0px;
    }
  }
}
.category-wrapper,
.upload-container {
  max-width: 800px;
}
.upload-container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @include for-mobile {
    width: 100%;
  }

  .upload-wrapper {
    border: 2px solid #000;
    padding: 10px;

    @include for-mobile {
      padding: 5px;
    }

    .sub-item {
      display: inline-block;
      width: 100%;
    }
  }

  .upload-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}
.subcategories-action-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 3px solid #000;

  @include for-mobile {
    button {
      font-size: 10px;
    }
  }

  button {
    &:nth-child(1) {
      margin-right: 10px;
    }
  }
}
.subcategories-wrapper {
  display: flex;
  flex-direction: row;
}
.subcategories-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .subcategories-section {
    width: 50%;
    .subcategories-item {
      border: 1px solid #000;
      padding: 10px;
      margin-bottom: 5px;
      min-height: 220px;

      @include for-mobile {
        padding: 5px;
        min-height: 180px;

        br {
          display: none;
        }
      }
      &:nth-child(odd) {
        margin-right: 5px;
      }
      &:nth-child(even) {
        margin-left: 5px;
      }
      .title {
        font-weight: bolder;
        margin-bottom: 10px;
        @include for-mobile {
          font-size: 12px;
        }
      }
      .input {
        margin-bottom: 10px;
        position: relative;
        input {
          width: 100%;
          border: 1px solid #000;
          padding: 5px;
          @include for-mobile {
            font-size: 8px;
          }
        }
        .progress {
          margin-top: 25px;
        }
        .close {
          position: absolute;
          top: -30px;
          right: 0;
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          font-weight: bolder;

          @include for-mobile {
            font-size: 10px;
          }

          &:hover {
            background-color: #ddd;
            cursor: pointer;
          }
        }

        .text-info {
          .permalink {
            overflow: hidden;
          }

          @include for-mobile {
            p {
              font-size: 12px;
              margin-bottom: 0;
            }
            .permalink {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.category-container {
  width: 90%;
  height: 88vh;
  margin: 40px auto 0px auto;

  .category-navigation {
    display: block;
    &:hover {
      cursor: pointer;
    }
  }
  .category-upper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    line-height: 100%;
    height: 10%;
  }
  .category-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 80%;
    border: 2px solid #000;
    margin: 0 auto;

    @include for-mobile {
      height: 60%;
    }
  }

  .category-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .category-item {
    border: 1px solid #000;
    text-align: center;
    min-height: 66px;
    padding: 20px;
    width: 100%;

    font-weight: 700;

    @include for-mobile {
      padding: 5px;
      line-height: 50px;
    }

    &.no-border {
      border: none;
      &:hover {
        background-color: #fff;
        cursor: default;
      }
    }

    &:hover {
      background-color: #ddd;
      cursor: pointer;
    }
  }
}
